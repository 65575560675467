import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent {
    /**
   * Get icon name
   */

    @Input() name: string | undefined;
    @Input() color = '';

}
