import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from '../../../services/utility.service';
import { AppConfig } from '../../../config/app.config';
import {
  ForgotPassword,
  LoginRequest,
  LoginResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SignupRequest,
  SignupResponse,
  VerifyRequest,
  VerifyResponse,
} from '../../../models/auth-model';
import { HttpService } from '../../../services/http.service';
import { Observable } from 'rxjs/internal/Observable';
import { CustomPagination, PaginatedResponse } from 'src/app/models/common-model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private router: Router,
    private httpService: HttpService,
    private utility: UtilityService
  ) {}
  /**
   * Makes a login request with the provided credentials.
   * Returns if login was successful or not.
   *
   * @param objLogin - User's email and password.
   */
  login(loginRequest: LoginRequest): Observable<LoginResponse> {
    this.utility.clearAllLocalStorageData();
    return this.httpService.post<LoginResponse>(
      AppConfig.AUTH.LOGIN_API,
      loginRequest
    );
  }

  /**
   * Makes a register request with the provided credentials.
   * Returns if register was successful or not.
   *
   * @param objSignup - User's email and password.
   */
  signup(signupRequest: SignupRequest): Observable<SignupResponse> {
    return this.httpService.post<SignupResponse>(
      AppConfig.AUTH.SIGNUP_API,
      signupRequest
    );
  }
  getAllGlobalLabSearch(
    customPaginaton: CustomPagination
  ): Observable<PaginatedResponse> {
    return this.httpService.getwithCustomPagination<PaginatedResponse>(
      AppConfig.AUTH.SEARCH_GLOBALLAB,
      customPaginaton
    );
  }

  /**
   *
   * API for Forget Password
   */

  forgotPassword(forgotPassword: ForgotPassword): Observable<ForgotPassword> {
    return this.httpService.post<ForgotPassword>(
      AppConfig.AUTH.FORGOT_PASSWORD,
      forgotPassword
    );
  }

  /**
   *
   * API for Verify Email
   */

  verifyEmail(verifyRequest: VerifyRequest): Observable<VerifyResponse> {
    return this.httpService.post<VerifyResponse>(
      AppConfig.AUTH.VERIFY_EMAIL,
      verifyRequest
    );
  }
  /**
   *
   * API for Verify Email
   */

  resetPassword(
    resetPasswordRequest: ResetPasswordRequest
  ): Observable<ResetPasswordResponse> {
    return this.httpService.post<ResetPasswordResponse>(
      AppConfig.AUTH.RESET_PASSWORD,
      resetPasswordRequest
    );
  }

  isUserLoggedIn(): boolean {
    const token = this.utility.getLocalStorageData('auth-token');
    if (token) {
      return true;
    }
    return false;
  }

  /**
   * Removes the auth token from local storage and navigates the user to login.
   */
  async logout(): Promise<void> {
    try {
      this.utility.clearAllLocalStorageData();
      this.router.navigate(['/login']);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  /**
   * refresh token api when access token expired
   * new access token and refresh token
   */
  async refreshToken(): Promise<any> {
    try {
      const result = await (<any>(
        this.httpService.get(AppConfig.AUTH.GET_TOKEN).toPromise()
      ));
      if (result && result?.status !== 'error') {
        return result;
      } else {
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    } catch (error: any) {
      console.log(error);
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  }
}
