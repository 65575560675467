
<ng-container [ngSwitch]="name">
    <div class="add-btn-container">
        <button *ngSwitchCase="'add-package'" class="add-btn">Add Package</button>
        <button *ngSwitchCase="'add-lab'" class="add-btn">Add New Lab</button>
        <button *ngSwitchCase="'add-offer'" class="add-btn">Add New Offer</button>
        <button *ngSwitchCase="'add-test'" class="add-btn">Add New Test</button>
        <button *ngSwitchCase="'add-appointment'" class="add-btn">Add New Appointment</button>
        <button *ngSwitchCase="'add-collector'" class="add-btn">Add New Collector</button>
        <button *ngSwitchCase="'add-user'" class="add-btn">Add New User</button>
        <button *ngSwitchCase="'add-parameters'" class="add-btn">Add Parameters</button>
        <button *ngSwitchCase="'add-instruction'" class="add-btn">Add Instructions</button>



    </div>
</ng-container>