import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
@Directive({
  selector: '[appChart]',
})
export class ChartDirective implements OnInit {
  @Input() chartType = 'bar';
  @Input() chartData: any;
  @Input() label: any;
  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.createChart();
  }

  private createChart() {
    const ctx = this.el.nativeElement.getContext('2d');
    new Chart(ctx, this.getChartConfig());
  }

  private getChartConfig(): any {
    return {
      type: this.chartType,
      data: {
        labels: this.chartData?.labels,
        datasets: [
          {
            label: `# of ${this.label}`,
            data: this.chartData?.data,
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: this.chartType === 'line',
        maintainAspectRatio: true,
      },
    };
  }
}
