import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  constructor(private router : Router){}
  @Input() name: string | undefined;
  onBack(){
    window.history.back(); // <-- go back to previous page on history stack
  }

}
