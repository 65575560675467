import { Platform } from '@angular/cdk/platform';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { PaginatedRequest, Pagination } from 'src/app/models/common-model';
import { AddLabsRequest } from 'src/app/models/lab-model';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { GlobalDataService } from 'src/app/services/globaldata.service';
import { LabService } from 'src/app/modules/collectionCenters/services/lab.service';
import { ProfileService } from 'src/app/modules/profile/services/profile.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
})
export class AdminHeaderComponent implements OnInit {
  imagePath!: string;
  imageLoader = false;
  isSkeletonLoading = true;
  @Output() sideNavToggled = new EventEmitter<boolean>();
  menuStatus = false;
  isAdminProfileShow = false;
  adminName!: string;
  isMobile!: boolean;
  userId = this.utilityService.getLocalStorageData('user') ?? '';
  labs: AddLabsRequest[] = [];
  access!: boolean;
  pagination = new Pagination();
  lab!: string;
  role!: string;
  isFirstLogin!: boolean;
  paginationRequest = new PaginatedRequest();
  pageSize = 1;
  loadedOptions: number = this.pageSize; // Initial loaded options
  allOptionsLoaded = false;
  isLoading = false;
  constructor(
    private router: Router,
    private platform: Platform,
    private elRef: ElementRef,
    private authService: AuthService,
    private commonService: CommonService,
    private utilityService: UtilityService,
    private profileService: ProfileService,
    private labService: LabService,
    private dataService: GlobalDataService,
    private loadingService: LoadingService
  ) {
    this.getUserLabs();
    this.isMobile = this.platform.ANDROID || this.platform.IOS;
    this.loadingService.loading$.subscribe((loading) => {
      this.isLoading = loading;
    });
  }

  ngOnInit(): void {
    this.adminName =
      this.utilityService.getLocalStorageData('admin-name') ?? '';
    this.userId = this.utilityService.getLocalStorageData('userId') ?? '';
    this.role = this.utilityService.getLocalStorageData('user_roles') ?? '';
    this.dataService.data$.subscribe((data) => {
      this.lab = data;
    });
  }
  async getUserLabs(): Promise<void> {
    this.labService.getAllLabs(this.paginationRequest).subscribe({
      next: (res) => {
        this.labs = res.data;
        this.isLoading = true;
        // Set the default lab value when the component initializes
        if (this.labs.length > 0) {
          this.utilityService.setLocalStorageData('labId', this.labs[0].id);
          this.dataService.setData(this.labs[0].id);
          this.getUserProfileImage();
        } else {
          this.dataService.setData('');
        }
        this.pagination = new Pagination(
          res.currentPage,
          res.totalItems,
          res.totalPages
        );
      },
      error: (e) => {
        this.isLoading = false;
        this.commonService.showErrorToaster('Error', e.message);
      },
    });
  }
  loadMoreLabs() {
    // Increment the current page before making the next API call
    this.paginationRequest.page++;
    this.labService.getAllLabs(this.paginationRequest).subscribe({
      next: (res) => {
        this.labs = [...this.labs, ...res.data];
        this.dataService.setData(this.labs[0].id);
        this.isLoading = true;
        this.pagination = new Pagination(
          res.currentPage,
          res.totalItems,
          res.totalPages
        );
      },
      error: (e) => {
        this.isLoading = false;
        this.commonService.showErrorToaster('Error', e.message);
      },
    });
  }

  toggelSideNav() {
    this.menuStatus = !this.menuStatus;
    console.log(this.menuStatus);
    this.dataService.setSideNavState(this.menuStatus);
    localStorage.setItem('sidebar', JSON.stringify(this.menuStatus));
  }

  logout() {
    this.authService.logout();
  }

  onLabSelect(event: any) {
    if (event.value) {
      this.access = true;
      const accessibilty = JSON.stringify(this.access);
      this.utilityService.setLocalStorageData('labId', event.value);
      this.dataService.setData(event.value);
      this.utilityService.setLocalStorageData('accessibilty', accessibilty);
    }
  }

  // fetching profile image
  async getUserProfileImage(): Promise<void> {
    this.imageLoader = true;
    this.profileService.getProfileImage().subscribe({
      next: (res) => {
        this.imagePath = environment.imagebaseurl + '/' + res.data;
        this.imageLoader = false;
      },
      error: (e) => {
        this.imageLoader = false;
      },
    });
  }
}
