  <!-- <mat-toolbar color="primary">
    <button mat-icon-button (click)="toggelSideNav()">
      <mat-icon>menu</mat-icon>
    </button>
    <img
    src="../../../../assets/images/LABSREPORT.png"
    alt=""
    style="max-width: 155px"
  />
    <span class="example-spacer"></span>
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <mat-form-field *ngIf="role === 'ROOT_USER'" ppearance="fill">
        <mat-label>Select Lab</mat-label>
        <mat-select (selectionChange)="onLabSelect($event)" [ngModel]="lab">
          <mat-option value="{{ item.id }}" *ngFor="let item of labs">{{
            item.name
            }}</mat-option>
          <mat-option *ngIf="pagination.currentPage < pagination.totalPages" (onSelectionChange)="loadMoreLabs()"
            class="show-more">
            <app-icons [name]="'dropdown'"></app-icons>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <img
      class="profile-img"
      src="{{
      imagePath ? imagePath : '../../../../../../assets/images/user-profile.png'
      }}"
      alt="User Profile"
    />
    </div>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon"
      [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu>
      <button mat-menu-item routerLink="personal-profile">Personal Profile</button>
      <button mat-menu-item>Settings</button>
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
  </mat-toolbar>
  <mat-progress-bar *ngIf="false" mode="indeterminate" color="accent"></mat-progress-bar>
  <app-loader *ngIf="isLoading"></app-loader> -->
  <!-- <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar> -->

  <mat-toolbar color="primary">
    <button mat-icon-button (click)="toggelSideNav()">
      <mat-icon>menu</mat-icon>
    </button>
    <img src="../../../../assets/images/logo.png" alt="" style="max-width: 155px"/>
    <span class="toolbar-item-spacer"></span>
    <div class="mt-4">
    <mat-form-field>
    <mat-select (selectionChange)="onLabSelect($event)" [ngModel]="lab">
      <mat-option value="{{ item.id }}" *ngFor="let item of labs">{{
        item.name
        }}</mat-option>
    </mat-select>
    </mat-form-field>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <img class="profile-img" src="{{ 
          imagePath ? imagePath : '../../../../../../assets/images/user-profile.png'
          }}" alt="User Profile" />
    </button>
    <mat-menu #menu>
      <button mat-menu-item routerLink="personal-profile">Personal Profile</button>
      <button mat-menu-item>Settings</button>
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
  </mat-toolbar>