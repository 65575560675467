import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BillDialogComponent } from '../components/bill-dialog/bill-dialog.component';
import { Param } from 'src/app/models/common-model';

@Injectable({
  providedIn: 'root',
})
export class BillDialogService {
  private billData!: Param;
  constructor(private dialog: MatDialog) {}

  setBillData(data: Param): void {
    this.billData = data;
  }

  getBillData(): Param {
    return this.billData;
  }

  openBillDialog(data: Param): void {
    this.setBillData(data);
    const dialogRef = this.dialog.open(BillDialogComponent, {
      width: '1200px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
