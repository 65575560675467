import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  @Input() declare customTemplate: TemplateRef<HTMLElement>;
  @Output() sendEvent = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  
/**
   * reset button press event to send false value
   */
 sendFalseValue(): void {
  this.sendEvent.emit({ event: 'false' });
}
/**
 * apply button press event to send true value
 */
sendTrueValue() {
  // this.dialogRef.close({ event: 'true' });
  this.sendEvent.emit({ event: 'true' });
}
}
