import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalDataService } from 'src/app/services/globaldata.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss'],
})
export class AdminSidebarComponent implements OnDestroy {
  isExpanded: boolean;
  currentLabId = '';
  private dataSubscription: Subscription;
  userRole: string = '';
  GivenName: boolean = false;
  constructor(
    private dataService: GlobalDataService,
    private router: Router,
    private utilityService: UtilityService
  ) {
    this.isExpanded = false;
    this.dataSubscription = this.dataService.data$.subscribe((labId) => {
      if (labId) {
        this.currentLabId = labId;
      }
    });
    const key = 'user_roles';
    const storedData = localStorage.getItem(key);
    if (storedData) {
      console.log(JSON.parse(atob(storedData)));
      this.userRole = JSON.parse(atob(storedData));
    }
  }
  ngOnDestroy(): void {
    if (this.dataSubscription) {
      console.log('unsubscribed from view lab');
      this.dataSubscription.unsubscribe();
    }
  }
  toggleTestMenu() {
    this.isExpanded = !this.isExpanded;
  }

  viewLab() {
    if (!this.currentLabId) {
      this.router.navigate(['/add-lab']);
    }
    this.router.navigate(['/view-lab', this.currentLabId]);
  }

  isCollectionCenter(): boolean {
    return this.userRole === 'Collection Center';
  }

  isRootUser(): boolean {
    return this.userRole === 'ROOT_USER';
  }
}
