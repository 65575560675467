import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogData } from 'src/app/models/common-model';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
  public dialogRef: MatDialogRef<GenericModalComponent>
  ) { 
    console.log(data);
    
  }

  /**
   *
   * @param value for getting button value for close that buton
   */
  close(value:string):void{
    this.dialogRef.close(value);
  }
}
