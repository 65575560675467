<div class="parent-class">
    <div class="close-icon" (click)="closeDialog()"><i class="fa fa-times" aria-hidden="true"></i></div>
<mat-form-field>
  <input
    matInput
    [(ngModel)]="searchText"
    (input)="onSearch()"
    placeholder="Search for a city"
  />
</mat-form-field>

<div class="popular-states">
  <h3 class="dialog-heading">Popular Cities</h3>
  <ul>
    <li *ngFor="let state of popularStates">
       <div class="city-img"> <img src="{{state.img}}" alt=""></div>
        {{ state.name }}</li>
  </ul>
</div>

<div class="city-dropdown" *ngIf="showDropdown && matchingCities.length > 0">
  <ul class="dropdown-search-list">
    <li *ngFor="let city of matchingCities">{{ city }}</li>
  </ul>
</div>

</div>