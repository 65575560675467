import { Component } from '@angular/core';
import { injectSpeedInsights } from '@vercel/speed-insights';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'labsReport';
  constructor() {
    injectSpeedInsights({});
  }
}
