import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../modules/auth/services/auth.service';
import { UtilityService } from '../services/utility.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private utilityService: UtilityService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    /**
     * get token form local storage
     */
    const token = this.utilityService.getLocalStorageData('auth-token');

    /**
     * if token then redirect to new routes
     */
    if (token) {
      return true;
    }

    /**
     * if token not found then redirect to login page
     */
    this.router.navigate(['login']);
    return false;
  }
}
