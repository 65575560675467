import { Component, Input, Self, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss']
})
export class TimeInputComponent implements ControlValueAccessor {
  @Input() label!: string;

  timeValue!: string;

  constructor(
    @Self() @Optional() public ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  writeValue(value: any): void {
    this.timeValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value: string) => void = () => {};

  setDisabledState(isDisabled: boolean): void {
    // Implement this method if needed.
  }

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.timeValue = this.formatTime(inputElement.value);
    this.onChange(this.timeValue);
    this.onTouched();
  }

  private formatTime(input: string): string {
    // Split the input string into individual components
    const parts = input.split(':').map(part => part.replace(/\D/g, ''));
    let hh = '';
    let mm = '';
    let ss = '';

    if (parts.length > 0) {
      hh = parts[0].substring(0, 2);
      if (parts.length > 1) {
        mm = parts[1].substring(0, 2);
        if (parts.length > 2) {
          ss = parts[2].substring(0, 2);
        }
      }
    }

    return `${hh}:${mm}:${ss}`;
  }

}
