import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../../../services/http.service';
import { AppConfig } from '../../../config/app.config';
import { Observable } from 'rxjs/internal/Observable';
import {
  CommonResponse,
  CustomPagination,
  PaginatedRequest,
  PaginatedResponse,
} from '../../../models/common-model';
import {
  BookingIdResponse,
  BookingResponse,
  BookingStatusRequest,
  CreateBookingRequest,
  CreateReport,
  CreateReportResponce,
  GetBookingByIdResponse,
  UpdateBookingRequest,
} from '../../../models/booking-model';
import { EnumsResponse } from '../../../models/master-model';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(private router: Router, private httpService: HttpService) {}

  createBooking(
    bookingRequest: CreateBookingRequest
  ): Observable<BookingResponse> {
    return this.httpService.post<BookingResponse>(
      AppConfig.BOOKING.CREATE_BOOKING,
      bookingRequest
    );
  }
  getAllBookings(
    paginatedRequest: PaginatedRequest
  ): Observable<PaginatedResponse> {
    return this.httpService.getWithPagination<PaginatedResponse>(
      AppConfig.BOOKING.BOOKING,
      paginatedRequest
    );
  }

  // Get all booking lab specific - lab admin
  getAllBookingsByLab(
    customPagination: CustomPagination
  ): Observable<PaginatedResponse> {
    return this.httpService.getwithCustomPagination<PaginatedResponse>(
      AppConfig.BOOKING.CREATE_BOOKING,
      customPagination
    );
  }

  // get all booking - super lab admin
  getAllBookingsFilter(
    customPaginaton: CustomPagination
  ): Observable<PaginatedResponse> {
    return this.httpService.getwithCustomPagination<PaginatedResponse>(
      AppConfig.BOOKING.BOOKING,
      customPaginaton
    );
  }
  getAllBookingsSearch(
    customPaginaton: CustomPagination
  ): Observable<PaginatedResponse> {
    return this.httpService.getwithCustomPagination<PaginatedResponse>(
      AppConfig.BOOKING.BOOKING_SEARCH,
      customPaginaton
    );
  }

  //search specific record from the list of bookinglist/medical history of a patient/customer
  getMedicalHistorySearch(
    customPaginaton: CustomPagination
  ): Observable<PaginatedResponse> {
    return this.httpService.getwithCustomPagination<PaginatedResponse>(
      AppConfig.BOOKING.BOOKING_PATIENT_SEARCH,
      customPaginaton
    );
  }
  // Lab Specific
  getBookingById(params: any): Observable<BookingResponse> {
    return this.httpService.get<BookingResponse>(
      AppConfig.BOOKING.BOOKING_BY_ID,
      { params: params }
    );
  }

  getBookingReportById(params: any): Observable<GetBookingByIdResponse> {
    return this.httpService.get<GetBookingByIdResponse>(
      AppConfig.BOOKING.BOOKING_BY_ID,
      { params: params }
    );
  }
  // get booking detail account specific
  getBookingDetailById(id: string): Observable<BookingIdResponse> {
    return this.httpService.get<BookingIdResponse>(
      AppConfig.BOOKING.BOOKING + `/${id}`
    );
  }
  getAllBookingsByDate(
    customPaginaton: CustomPagination
  ): Observable<PaginatedResponse> {
    return this.httpService.getwithCustomPagination<PaginatedResponse>(
      AppConfig.BOOKING.BOOKING,
      customPaginaton
    );
  }
  updateBooking(updateBooking: UpdateBookingRequest) {
    return this.httpService.put<BookingResponse>(
      AppConfig.BOOKING.CREATE_BOOKING,
      updateBooking
    );
  }
  getHearAboutDropdown(params: any): Observable<EnumsResponse> {
    return this.httpService.get<EnumsResponse>(AppConfig.BOOKING.BOOKINGS, {
      params: params,
    });
  }

  // update booking status
  updateBookingStatus(obj: BookingStatusRequest) {
    return this.httpService.put<BookingResponse>(
      AppConfig.BOOKING.BOOKING_STATUS,
      obj
    );
  }

  //get the bookinglist/medical history of a patient/customer
  getBookingListByPatient(
    customPagination: CustomPagination
  ): Observable<PaginatedResponse> {
    return this.httpService.getwithCustomPagination<PaginatedResponse>(
      AppConfig.BOOKING.BOOKING_DETAIL,
      customPagination
    );
  }
  getBillData(params: {
    id: string;
    labId: string;
  }): Observable<CommonResponse> {
    return this.httpService.get<CommonResponse>(
      AppConfig.BOOKING.GET_BILL_DATA,
      {
        params: params,
      }
    );
  }
  getIssueData(params: {
    id: string;
    labId: string;
  }): Observable<CommonResponse> {
    return this.httpService.get<CommonResponse>(
      AppConfig.BOOKING.GET_ISSUE_DATA,
      {
        params: params,
      }
    );
  }

  getReportData(params: {
    id: string;
    labId: string;
  }): Observable<CommonResponse> {
    return this.httpService.get<CommonResponse>(
      AppConfig.BOOKING.GET_REPORT_DATA,
      {
        params: params,
      }
    );
  }
  createReport(createReport: CreateReport): Observable<CreateReportResponce> {
    return this.httpService.post<CreateReportResponce>(
      AppConfig.BOOKING.CREATE_REPORT,
      createReport
    );
  }

  editReport(editReport: CreateReport): Observable<CreateReportResponce> {
    return this.httpService.put<CreateReportResponce>(
      AppConfig.BOOKING.EDIT_REPORT,
      editReport
    );
  }
}
