import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../../../services/http.service';
import { AppConfig } from '../../../config/app.config';
import { Observable } from 'rxjs/internal/Observable';
import {
  AddLabsRequest,
  AddLabsResponse,
  Image,
  Lab,
  defaultImage,
} from '../../../models/lab-model';
import {
  CommonResponse,
  CustomPagination,
  PaginatedRequest,
  PaginatedResponse,
} from '../../../models/common-model';

@Injectable({
  providedIn: 'root',
})
export class LabService {
  constructor(private router: Router, private httpService: HttpService) {}

  getAllLabs(
    paginatedRequest: PaginatedRequest
  ): Observable<PaginatedResponse> {
    return this.httpService.getWithPagination<PaginatedResponse>(
      AppConfig.LABS.LAB,
      paginatedRequest
    );
  }

  addLab(addLabs: AddLabsRequest): Observable<AddLabsResponse> {
    return this.httpService.post<AddLabsResponse>(AppConfig.LABS.LAB, addLabs);
  }

  getLabById(id: string): Observable<AddLabsResponse> {
    return this.httpService.get<AddLabsResponse>(AppConfig.LABS.LAB + '/' + id);
  }
  updateLabName(updateLab: AddLabsRequest) {
    return this.httpService.put<AddLabsResponse>(AppConfig.LABS.LAB, updateLab);
  }

  getAllLabSearch(
    customPaginaton: CustomPagination
  ): Observable<PaginatedResponse> {
    return this.httpService.getwithCustomPagination<PaginatedResponse>(
      AppConfig.LABS.SEARCH,
      customPaginaton
    );
  }
  getAllLabFilter(
    customPaginaton: CustomPagination
  ): Observable<PaginatedResponse> {
    return this.httpService.getwithCustomPagination<PaginatedResponse>(
      AppConfig.LABS.LAB,
      customPaginaton
    );
  }

  //  upload lab images
  uploadLabImages(
    file: any,
    params: { labId: string }
  ): Observable<CommonResponse> {
    return this.httpService.post<CommonResponse>(
      AppConfig.LABS.LAB_IMAGES,
      file,
      { params: params }
    );
  }
 
  defaultLabImages(defaltImages: defaultImage) {
    return this.httpService.put<AddLabsResponse>(AppConfig.LABS.LAB_IMG, defaltImages);
  }

  // get lab images
  getLabImages(params: { labId: string }): Observable<CommonResponse> {
    return this.httpService.get<CommonResponse>(AppConfig.LABS.LAB_IMAGES, {
      params: params,
    });
  }

  //  upload offer images
  uploadOfferImages(
    file: any,
    params: { labId: string }
  ): Observable<CommonResponse> {
    return this.httpService.post<CommonResponse>(AppConfig.LABS.OFFER, file, {
      params: params,
    });
  }
  // get lab images
  getLaboffer(params: { labId: string }): Observable<CommonResponse> {
    return this.httpService.get<CommonResponse>(AppConfig.LABS.OFFERS, {
      params: params,
    });
  }
  deleteLaboffer(params: { labId: string }): Observable<CommonResponse> {
    return this.httpService.delete<CommonResponse>(
      AppConfig.LABS.DELETE_OFFER,
      { params: params }
    );
  }
  deleteLabImage(params: { labId: string }): Observable<CommonResponse> {
    return this.httpService.delete<CommonResponse>(
      AppConfig.LABS.DELETE_IMAGE,
      { params: params }
    );
  }
}
