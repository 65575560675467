  <div class="parent-class">
  <div class="first-child child-box">
    <div class="">Address</div>
    <input type="text">
 </div>
 <div class="second-child">
    <div class="child-box">
        <div class="label">Country</div>
        <input type="text" name="" id="country" value="India" readonly> 
      </div>
      <div class="child-box">
        <div class="label">State</div>
        <input type="text" name="" id="state" value=""> 
      </div>
      <div class="child-box">
        <div class="label">City</div>
        <input type="text" name="" id="city" value=""> 
      </div>
      <div class="child-box">
        <div class="label">PinCode</div>
        <input type="text" name="" id="pinCode" value=""> 
      </div>
 </div>
<div class="d-flex">
    <button class="btn submit-btn">Submit</button>
    <button class="btn custom-edit-btn" (click)="closeDialog()">Close</button>
</div>
  </div>