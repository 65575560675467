import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Output() searchTermChange = new EventEmitter<string>();
  inputSearchTerm = '';

  onInputChange(): void {
    this.searchTermChange.emit(this.inputSearchTerm);
  }
}
