import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-h4',
  templateUrl: './h4.component.html',
  styleUrl: './h4.component.scss',
})
export class H4Component {
  @Input() inputText = '';
}
