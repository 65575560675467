<div class="side-nav-content">
  <ul class="nav-list mt-1">
    <li class="nav-list-item" routerLinkActive="active-link" routerLink="dashboard"><i
        class="fa fa-line-chart"></i>Dashboard</li>
    <li class="nav-list-item" routerLinkActive="active-link" routerLink="add-appointment"><i
        class="fa fa-address-card"></i>Add Appointment</li>
    <li class="nav-list-item" routerLinkActive="active-link" routerLink="appointment-list"><i
        class="fa fa-calendar"></i>Appointment List</li>
    <li class="nav-list-item" *ngIf="isCollectionCenter() ||isRootUser() " routerLinkActive="active-link"
      routerLink="all-booking"><i class="fa-sharp fa-solid fa-sheet-plastic"></i>Enter & Verify</li>
    <li>
      <div class="nav-list-item custom-space-btw" routerLinkActive="active" (click)="toggleTestMenu()">
        <div class="custom-flex"><i class="fa fa-list-alt"></i>Tests</div>
        <i class="fa" [ngClass]="{'fa-chevron-up': isExpanded, 'fa-chevron-down': !isExpanded}"
          style="font-size: 15px;"></i>
      </div>
      <ul class="test-dropdown" *ngIf="isExpanded">
        <li routerLink="test-list" routerLinkActive="active-link"><i class="fa fa-vials"></i>Test List</li>
        <li routerLink="package-list" routerLinkActive="active-link"><i class="fa fa-box"></i>Package List</li>
        <li routerLink="offer-list" routerLinkActive="active-link"><i class="fa fa-envelope-open"></i>Offer List</li>
      </ul>
    </li>
    <li class="nav-list-item" *ngIf="isCollectionCenter() || isRootUser()" routerLinkActive="active-link"
      routerLink="center-list"><i class="fa fa-bar-chart"></i>Collection Centers</li>
    <li class="nav-list-item" routerLinkActive="active-link" routerLink="view-lab" (click)="viewLab()"><i
        class="fa fa-flask"></i>Collection Center Profile
    </li>


    <li class="nav-list-item" *ngIf="isCollectionCenter() || isRootUser()" routerLinkActive="active-link"
      routerLink="user-list"><i class="fa fa-users"></i>Users
    </li>
    <li class="nav-list-item" *ngIf="isCollectionCenter() || isRootUser()" routerLinkActive="active-link"
      routerLink="list-samplecollector">
      <i class="fa-solid fa-user"></i>Sample collector
    </li>

    <!-- <li class="nav-list-item" *ngIf="isCollectionCenter() || isRootUser()" routerLinkActive="active-link"
      routerLink="list-lab">
      <i class="fa-solid fa-user"></i>Connect lab
    </li> -->
<li class="nav-list-item" *ngIf="isCollectionCenter() || isRootUser()" routerLinkActive="active-link"
      routerLink="contact-us">
      <i class="fa fa-address-book" aria-hidden="true"></i>Contact Us
    </li>

  </ul>
</div>