import { InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken('app.config');
export const AppConfig = {
  AUTH: {
    LOGIN_API: 'auth/login',
    SIGNUP_API: 'account',
    GET_TOKEN: 'auth/get_token',
    LOGOUT: 'user/logout',
    CHANGE_PASSWORD: 'user/pwd',
    FORGOT_PASSWORD: 'user/forget-password',
    RESET_PASSWORD: 'user/reset-password',
    VERIFY_EMAIL: 'user/verification',
    SEARCH_GLOBALLAB:'globalLab/search'
  },
  LABS: {
    LAB: 'lab',
    LAB_IMAGES: 'lab/logos',
    LAB_IMG: 'lab/make-logo',
    PACKAGE: 'package',
    DEFAULT: 'package/featured',
    GET_PACKAGE: 'package/get',
    SEARCH: 'lab/search',
    OFFER: 'offer',
    OFFERS: 'offer/offer-path',
    DELETE_OFFER: 'offer/offer',
    DELETE_IMAGE: 'lab/logo',
  },
  ADMIN: {
    USER: 'admin',
    ROLES: 'admin/user-roles',
    ASSIGN_ROLES: 'admin/update-roles',
    ASSIGN_LABS: 'admin/assign-labs',
    UPDATE_STATUS: 'admin/update-status',
    SEARCH: 'admin/search',
    USER_LABS: 'lab/get-labs',
    COLLECTOR: 'collector',
    ACCOUNT_COLLECTOR: 'admin/collector',
  },
  TEST: {
    TEST: 'test',
    LAB_TEST: 'admin/labTest',
    GET_LAB_TEST: 'admin/labTest/get',
    CATEGORY_LIST: 'globalTest/category-list',
    SEARCH_TEST: 'admin/labTest/search',
    CREATE_LAB_TEST: 'admin/labTest/create',
    TEST_METHOD: 'admin/labTest/method',
    GET_OPTIONS: 'admin/labTest/options',
    CREATE_TESTMETHOD: 'admin/labTest/method',
    CREATE_OPTIONS: 'admin/labTest/options',
  },
  BOOKING: {
    BOOKINGS: 'booking/hearAbout',
    BOOKING: 'admin/booking',
    BOOKING_BY_ID: 'booking/get',
    CREATE_BOOKING: 'booking',
    BOOKING_SEARCH: 'booking/search',
    BOOKING_STATUS: 'booking/update-status',
    BOOKING_DETAIL: 'booking/booking-details',
    BOOKING_PATIENT_SEARCH: 'booking/booking-search',
    GET_BILL_DATA: 'booking/bill',
    GET_REPORT_DATA: 'booking/report',
    CREATE_REPORT: 'booking/create-report',
    EDIT_REPORT: 'booking/update-report',
    GET_ISSUE_DATA: 'booking/issue',
  },
  PROFILE: {
    PROFILE: 'userprofile',
    BOOKING: 'admin/booking',
    GET_PROFILE_IMAGE: 'userprofile/profile-pic',
    CONTACT_US: 'contact-us/collectionCenter'
  },
  ENUMS: {
    ENUM: 'common-data',
  },
  COMMON_DATA: {
    ROLE: '8d51eb2a-eba0-418c-8e73-46a4ca417880',
  },
  DASHBOARD: {
    DASHBOARD_DATA: 'dashboard/overview',
    DASHBOARD_CHART_DATA: 'dashboard/revenue-graph',
    DASHBOARD_PIE_CHART_DATA: 'dashboard/top-tests',
    DASHBOARD_TEST_GRAPH_DATA: 'dashboard/test-graph',
    DASHBOARD_REVENUE_GRAPH: 'dashboard/revenue-graph',
    DASHBOARD_PATIENT_GRAPH: 'dashboard/patient-graph',
  },
  Report: {
    GET_REPORT_PATH: 'booking/get-report',
    GET_ALL_BOOKING_IN_PROGRESS: 'booking/in-progress',
  },
};
