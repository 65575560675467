import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BillDialogService } from 'src/app/modules/appointment/services/bill-dialog.service';
import { BookingService } from 'src/app/modules/appointment/services/booking.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bill-dialog',
  templateUrl: './bill-dialog.component.html',
  styleUrls: ['./bill-dialog.component.scss'],
})
export class BillDialogComponent {
  pdfUrl: SafeResourceUrl | null = null;
  isPageLoader = false;
  constructor(
    public dialogRef: MatDialogRef<BillDialogComponent>,
    private billService: BillDialogService,
    private bookingService: BookingService,
    private commonService: CommonService,
    private sanitizer: DomSanitizer
  ) {
    this.getBookingDetails(
      this.billService.getBillData().id,
      this.billService.getBillData().labId
    );
  }

  private async getBookingDetails(id: string, labId: string): Promise<void> {
    this.isPageLoader = true;
    try {
      this.bookingService.getBillData({ id: id, labId: labId }).subscribe({
        next: (response) => {
          this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            environment.imagebaseurl + '/' + response.data
          );
          console.log(this.pdfUrl);
          this.isPageLoader = false;
        },
        error: (e) => {
          this.isPageLoader = false;
          this.commonService.showErrorToaster('Error', e);
          this.isPageLoader = false;
        },
      });
    } catch (error) {
      this.isPageLoader = false;
      this.commonService.showErrorMessage(error);
    }
  }
}
