// data.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalDataService {
  private dataSubject = new BehaviorSubject<any>(null);
  public data$ = this.dataSubject.asObservable();
  private sideNavSubject = new BehaviorSubject<any>(null);
  public sideNavState$ = this.sideNavSubject.asObservable();
  private loadingSubject = new BehaviorSubject<any>(null);
  public loadingState$ = this.loadingSubject.asObservable();

  setData(data: any): void {
    this.dataSubject.next(data);
  }
  setSideNavState(data: any) {
    this.sideNavSubject.next(data);
  }
  setLoadingState(data: any) {
    this.loadingSubject.next(data);
  }
}
