import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, JsonPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminHeaderComponent } from './components/admin-header/admin-header.component';
import { AdminFooterComponent } from './components/admin-footer/admin-footer.component';
import { AdminSidebarComponent } from './components/admin-sidebar/admin-sidebar.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { IconsComponent } from './components/icons/icons.component';
import { ToastrModule } from 'ngx-toastr';
import { LoaderComponent } from './components/loaders/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SearchbarComponent } from './components/common/searchbar/searchbar.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { ButtonComponent } from './components/common/button/button.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FilterComponent } from './components/filter/filter.component';
import { TimeInputComponent } from './components/common/time-input/time-input.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatSliderModule } from '@angular/material/slider';
import { AddBtnComponent } from './components/common/add-btn/add-btn.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { H1Component } from './components/header/h1/h1.component';
import { H2Component } from './components/header/h2/h2.component';
import { BtnComponent } from './components/buttons/btn/btn.component';
import { SubType1Component } from './components/sub-header/sub-type1/sub-type1.component';
import { NoDataFoundComponent } from './components/default-msg/no-data-found/no-data-found.component';
import { SearchComponent } from './components/search/search.component';
import { BillDialogComponent } from '../appointment/components/bill-dialog/bill-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { PageLoaderDirective } from './directives/loader/data.loader.directive';
import { ImageUploaderComponent } from 'src/app/modals/image-uploader/image-uploader.component';
import { LocationDialogComponent } from 'src/app/modals/location-dialog/location-dialog.component';
import { AddAddressDialogComponent } from 'src/app/modals/add-address-dialog/add-address-dialog.component';
import { EditAddressDialogComponent } from 'src/app/modals/edit-address-dialog/edit-address-dialog.component';
import { GenericModalComponent } from 'src/app/modals/generic-modal/generic-modal.component';
import { ChartDirective } from './directives/chart/chart.directive';
import { PrintService } from 'src/app/services/print.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { H3Component } from './components/header/h3/h3.component';
import { H4Component } from './components/header/h4/h4.component';
import { H5Component } from './components/header/h5/h5.component';
import { H6Component } from './components/header/h6/h6.component';
import { HttpClientModule } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  declarations: [
    AdminHeaderComponent,
    AdminFooterComponent,
    AdminSidebarComponent,
    LocationDialogComponent,
    AddAddressDialogComponent,
    EditAddressDialogComponent,
    IconsComponent,
    LoaderComponent,
    GenericModalComponent,
    AddBtnComponent,
    SearchbarComponent,
    ButtonComponent,
    PageNotFoundComponent,
    PageLoaderDirective,
    LoaderComponent,
    FilterComponent,
    TimeInputComponent,
    ImageUploaderComponent,
    H1Component,
    H2Component,
    H3Component,
    H4Component,
    H5Component,
    H6Component,
    BtnComponent,
    SubType1Component,
    NoDataFoundComponent,
    SearchComponent,
    BillDialogComponent,
    ChartDirective,
  ],
  imports: [
    MatIconModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSelectModule,
    MatOptionModule,
    RouterModule,
    MatInputModule,
    FlexLayoutModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatTableModule,
    AngularEditorModule,
    FormsModule,
    HttpClientModule,
  ],
  exports: [
    ReactiveFormsModule,
    AdminHeaderComponent,
    AdminFooterComponent,
    AdminSidebarComponent,
    IconsComponent,
    PageNotFoundComponent,
    PageLoaderDirective,
    LoaderComponent,
    FilterComponent,
    JsonPipe,
    FormsModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatDialogModule,
    MatTabsModule,
    MatSortModule,
    RouterModule,
    SlickCarouselModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    ToastrModule,
    MatProgressSpinnerModule,
    AddBtnComponent,
    SearchbarComponent,
    MatRadioModule,
    MatCardModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatButtonModule,
    ButtonComponent,
    MatGridListModule,
    MatListModule,
    MatSlideToggleModule,
    TimeInputComponent,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatChipsModule,
    ImageUploaderComponent,
    MatSliderModule,
    FlexLayoutModule,
    H1Component,
    H2Component,
    H3Component,
    H4Component,
    H5Component,
    H6Component,
    BtnComponent,
    SubType1Component,
    NoDataFoundComponent,
    SearchComponent,
    MatTooltipModule,
    MatStepperModule,
    DatePipe,
    ChartDirective,
    MatDialogActions,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatTableModule,
    AngularEditorModule,
  ],
  providers: [
    DatePipe,
    PrintService,
    {
      provide: Window,
      useValue: window,
    },
  ],
})
export class SharedModule {}
