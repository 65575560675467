import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../../../services/http.service';
import { AppConfig } from '../../../config/app.config';
import { Observable } from 'rxjs/internal/Observable';
import { CommonResponse } from '../../../models/common-model';
import { ContactUs, ProfileRequest, ProfileResponse } from '../../../models/profile.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private router: Router, private httpService: HttpService) {}
  editProfile(data: ProfileRequest): Observable<ProfileResponse> {
    return this.httpService.put<ProfileResponse>(
      AppConfig.PROFILE.PROFILE,
      data
    );
  }

  getProfile(): Observable<ProfileResponse> {
    return this.httpService.get<ProfileResponse>(AppConfig.PROFILE.PROFILE);
  }

  // Get Profile Image
  getProfileImage(): Observable<CommonResponse> {
    return this.httpService.get<CommonResponse>(
      AppConfig.PROFILE.GET_PROFILE_IMAGE
    );
  }
  // Update Profile Image
  updateProfileImage(imageData: any): Observable<CommonResponse> {
    return this.httpService.put<CommonResponse>(
      AppConfig.PROFILE.GET_PROFILE_IMAGE,
      imageData
    );
  }
  contactUs(
    contactus: ContactUs
  ): Observable<CommonResponse> {
    return this.httpService.post<CommonResponse>(
      AppConfig.PROFILE.CONTACT_US,
      contactus
    );
  }
  
}
