<div class="component-container">
  <div class="header">
    <h4>Comment</h4>
  </div>

  <div class="dropdown-container">
    <select id="commentDropdown" class="comment-dropdown">
      <option value="comment1">Comment 1</option>
      <option value="comment2">Comment 2</option>
      <option value="comment3">Comment 3</option>
    </select>

    <app-btn text="+ Add comment" class="add-comment-button">Add Comment</app-btn>
  </div>

  <div class="table-container">
    <table class="custom-table">
      <thead>
        <tr>
          <th>Comment</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Name</td>
          <td><app-btn text="Remove" color="warn">Remove</app-btn></td>
        </tr>
        <!-- Add more rows as needed -->
      </tbody>
    </table>
  </div>

  <app-btn text="close" color="basic" class="close-button">Close</app-btn>
</div>