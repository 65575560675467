<div class="search-container">
    <label for="search"
      ><i class="fa fa-search mr-6" aria-hidden="true"></i
    ></label>
    <input
      type="text"
      name=""
      id="search"
      class="input-search"
    />
</div>