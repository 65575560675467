import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss']
})
export class LocationDialogComponent {
 constructor(private dialogRef:DialogRef){}

  searchText = '';
  popularStates=[
    { "name": "Agra", "img": "assets/images/city-icon/taj-mahal.png" },
    { "name": "Maharashtra", "img": "assets/images/city-icon/big-ben.png" },
    { "name": "Gurgaon", "img": "assets/images/city-icon/skyline.png" },
    { "name": "Delhi", "img": "assets/images/city-icon/gate-of-india.png" },
    { "name": "Tamil Nadu", "img": "assets/images/city-icon/sri.png" },
    { "name": "Noida", "img": "assets/images/city-icon/akshardham-temple.png" },
    { "name": "Jaipur", "img": "assets/images/city-icon/jaipur.png" }
  ]
  matchingCities: string[] = [];
  showDropdown = false;
  selectedCity = '';

  onSearch() {
    // Implement your search logic here.
    // For example, you can filter cities based on the searchText.
    this.matchingCities = this.getMatchingCities(this.searchText);

    // Show or hide the dropdown based on whether there are matching cities.
    this.showDropdown = this.matchingCities.length > 0;
  }

  getMatchingCities(searchText: string): string[] {
    // Replace this with your actual city data and matching logic.
    // Here, we use a simple example with a predefined list of cities.
    const cities = ['Bangalore', 'Mumbai', 'Chennai', 'Delhi', 'Kochi', 'Hyderabad'];
    return cities.filter(city => city.toLowerCase().includes(searchText.toLowerCase()));
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
