import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sub-type1',
  templateUrl: './sub-type1.component.html',
  styleUrls: ['./sub-type1.component.scss']
})
export class SubType1Component {
  @Input() inputText = '';

}
