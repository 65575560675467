export const environment = {
  production: false,
  baseUrl: 'https://hospitalmala.com/api',
  apiUrl: 'https://www.universal-tutorial.com/api/getaccesstoken',
  api_token:
    '2dpKD_Q8u12gQR91LvBDNrXu2rQTe5rgpwECbtJv_gMYsS4mHG3ZCVzXzTe5lKmwzdM',
  user_email: 'deeptis9264@gmail.com',
  countryUrl: 'https://www.universal-tutorial.com/api/countries/',
  auth_token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJfZW1haWwiOiJkZWVwdGlzOTI2NEBnbWFpbC5jb20iLCJhcGlfdG9rZW4iOiIyZHBLRF9ROHUxMmdRUjkxTHZCRE5yWHUyclFUZTVyZ3B3RUNidEp2X2dNWXNTNG1IRzNaQ1Z6WHpUZTVsS213emRNIn0sImV4cCI6MTY5MDQ1NTkzN30.DTGlwwkcQpHbF5EzI8a8uzSKNRIwAxcFhv9WFqVc_5w',
  stateUrl: 'https://www.universal-tutorial.com/api/states/',
  cityUrl: 'https://www.universal-tutorial.com/api/cities/',
  imagebaseurl: 'https://labsreport.s3.ap-south-1.amazonaws.com',
};
