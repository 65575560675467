import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-btn',
  templateUrl: './btn.component.html',
  styleUrls: ['./btn.component.scss'],
})
export class BtnComponent {
  @Input() text = '';
  @Input() type = 'raised';
  @Input() color = 'primary';
  @Input() icon = '';
}
