import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appPageLoader]',
})
export class PageLoaderDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.createLoader();
  }

  private createLoader() {
    const pageLoader = this.renderer.createElement('div');
    this.renderer.addClass(pageLoader, 'page-loader');

    for (let i = 0; i < 3; i++) {
      const line = this.renderer.createElement('div');
      this.renderer.addClass(line, 'line');
      this.renderer.appendChild(pageLoader, line);
    }

    this.renderer.appendChild(this.el.nativeElement, pageLoader);
  }
}
