import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  // Set localstorage data
  setLocalStorageData(key: string, value: string) {
    const encode = JSON.stringify(value);
    localStorage.setItem(key, window.btoa(encode));
  }

  getLocalStorageData(key: string) {
    try {
      const item = localStorage.getItem(key);
      if (item) {
        const decodedData = window.atob(item);
        return JSON.parse(decodedData);
      }
      return null;
    } catch (error) {
      console.error(
        `Error retrieving data from localStorage for key ${key}:`,
        error
      );
      return null;
    }
  }
  /**
   * remove key data from local storage.
   */
  removeLocalStorageData(key: string): void {
    localStorage.removeItem(key);
  }
  /**
   * clear all local storage data.
   */
  clearAllLocalStorageData(): void {
    localStorage.clear();
  }
}
