import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../modules/auth/services/auth.service';
import { CommonService } from './common.service';
import { UtilityService } from './utility.service';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import {
  EMPTY,
  Observable,
  catchError,
  from,
  switchMap,
  throwError,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService {
  constructor(
    private router: Router,
    private authService: AuthService,
    private commonService: CommonService,
    private utilityService: UtilityService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Add authorization header if a user is authenticated
    const token = this.utilityService.getLocalStorageData('auth-token');
    if (token) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        const message = error.error.message
          ? error.error.message
          : error.message;

        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${message}`;
          return throwError(() => new Error(errorMsg));
        }

        switch (error.status) {
          case 400:
            // Bad Request
            errorMsg = `${message}`;
            break;

          case 401:
            // Not Authorized
            this.handleUnauthorizedError(message);
            break;

          case 402:
            // ReAuthenticate User
            return this.handleRefreshToken(request, next);

          case 403:
            // Access Denied
            this.handleAccessDeniedError(message);
            return EMPTY;
          case 404:
            // Not Authorized
            errorMsg = `${message}`;
            break;
          case 409:
            // Not Authorized
            errorMsg = `${message}`;
            break;

          case 500:
            // Internal Server Error
            errorMsg = error.message;
            break;
        }

        return throwError(() => new Error(errorMsg));
      })
    );
  }

  private handleUnauthorizedError(message: string) {
    this.commonService.showErrorToaster(`Error`, `${message}`);
    this.utilityService.clearAllLocalStorageData();
    this.router.navigate(['/login']);
  }

  private handleRefreshToken(request: HttpRequest<any>, next: HttpHandler) {
    return from(this.authService.refreshToken()).pipe(
      switchMap((token: any) => {
        this.utilityService.setLocalStorageData(
          'auth-token',
          token.data.access_token
        );
        this.utilityService.setLocalStorageData(
          'refresh-token',
          token.data.refresh_token
        );
        return next.handle(
          this.addTokenHandler(request, token.data.access_token)
        );
      }),
      catchError((error) => {
        // Handle the error and stop the execution flow
        console.error('Failed to refresh token:', error);
        localStorage.clear();
        this.router.navigate(['login']);
        return throwError(
          () => new Error('Something bad happened; please try again later.')
        );
      })
    );
  }

  private handleAccessDeniedError(message: string) {
    this.commonService.showErrorToaster(`Error`, `${message}`);
    this.router.navigate(['/dashboard']);
  }

  private addTokenHandler(request: HttpRequest<any>, token: string) {
    console.log('Token being added:', token);
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }
}
