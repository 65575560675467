import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  CustomPagination,
  HttpOptions,
  PaginatedRequest,
} from '../models/common-model';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 0) {
      console.error('An error occurred:', error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.message
      );
    }
    return throwError(() => new Error(error.message));
  }

  // GET with pagination request
  getWithPagination<T>(
    path: string,
    paginatedRequest: PaginatedRequest,
    options?: HttpOptions
  ): Observable<T> {
    return this.http
      .get<T>(
        `${environment.baseUrl}/${path}${paginatedRequest.toQueryParams()}`,
        options
      )
      .pipe(catchError(this.handleError));
  }
  // GET with custom pagination request
  getwithCustomPagination<T>(
    path: string,
    customPagination: CustomPagination,
    options?: HttpOptions
  ): Observable<T> {
    return this.http
      .get<T>(
        `${environment.baseUrl}/${path}${customPagination.toQueryParams()}`,
        options
      )
      .pipe(catchError(this.handleError));
  }

  // GET request
  get<T>(path: string, options?: HttpOptions): Observable<T> {
    return this.http
      .get<T>(`${environment.baseUrl}/${path}`, options)
      .pipe(catchError(this.handleError));
  }

  // POST request
  post<T>(path: string, data: object, options?: object): Observable<T> {
    return this.http
      .post<T>(`${environment.baseUrl}/${path}`, data, options)
      .pipe(catchError(this.handleError));
  }

  // PUT request
  put<T>(path: string, data: object, options?: object): Observable<T> {
    return this.http
      .put<T>(`${environment.baseUrl}/${path}`, data, options)
      .pipe(catchError(this.handleError));
  }

  // DELETE request
  delete<T>(path: string, options?: object): Observable<T> {
    return this.http
      .delete<T>(`${environment.baseUrl}/${path}`, options)
      .pipe(catchError(this.handleError));
  }

  // PATCH request
  patch<T>(path: string, data: object, options?: object): Observable<T> {
    return this.http
      .patch<T>(`${environment.baseUrl}/${path}`, data, options)
      .pipe(catchError(this.handleError));
  }

  // Multipart request
  multipart<T>(
    path: string,
    formData: FormData,
    options?: object
  ): Observable<T> {
    const headers = new HttpHeaders();
    return this.http
      .post<T>(`${environment.baseUrl}/${path}`, formData, {
        headers,
        ...options,
      })
      .pipe(catchError(this.handleError));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  convertObjectToParams(obj: any, params: any, parentKey = ''): HttpParams {
    Object.entries(obj).forEach(([key, value]) => {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      if (value !== null && value !== undefined) {
        if (typeof value === 'object') {
          this.convertObjectToParams(value, params, currentKey);
        } else {
          params = params.set(currentKey, value.toString());
        }
      }
    });
    console.log(params);
    return params;
  }
}
