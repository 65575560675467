import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent {
  @Input() declare customImagePopTemplate: TemplateRef<HTMLElement>;
  @Output() sendEvent = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  

}
