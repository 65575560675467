<ng-container [ngSwitch]="name">
    <button mat-button class="save-btn" *ngSwitchCase="'apply'">Apply</button>
    <button mat-button class="save-btn" *ngSwitchCase="'save'">Save</button>
    <button mat-button class="save-btn" *ngSwitchCase="'view'">View</button>
    <button mat-button class="back-btn" *ngSwitchCase="'reset'">Reset</button>
    <button mat-button class="save-btn" *ngSwitchCase="'reset-password'">Reset Password</button>
    <button mat-button class="save-btn" *ngSwitchCase="'edit'">Edit</button>
    <button mat-button class="back-btn" *ngSwitchCase="'remove'">Remove</button>
    <button mat-button class="save-btn" *ngSwitchCase="'update-role'">Assign Role</button>
    <button mat-button class="save-btn" *ngSwitchCase="'update-lab'">Assign Lab</button>
    <button mat-button class="back-btn" *ngSwitchCase="'back'">Back</button>
    <button mat-button class="save-btn" *ngSwitchCase="'login'" >Login</button>
    <button mat-button class="save-btn" *ngSwitchCase="'signup'" >Sign up</button>

</ng-container>
