import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-address-dialog',
  templateUrl: './edit-address-dialog.component.html',
  styleUrls: ['./edit-address-dialog.component.scss']
})
export class EditAddressDialogComponent {
  constructor(private dialogRef: MatDialogRef<EditAddressDialogComponent>) { }
  closeDialog(): void {
    this.dialogRef.close(); 
  }
}
