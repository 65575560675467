import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { UtilityService } from './utility.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private utilityService: UtilityService
  ) {}

  getTodaysDate(): Date {
    return new Date();
  }

  formatToCustomDate(date: Date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy') || '';
  }

  /**
   * show success toaster
   * @param title -> title for toaster
   * @param message -> message for toaster
   * @param isShowCloseButton -> close button show/hide default true
   */
  showSuccessToaster(title: string, message: string, isShowCloseButton = true) {
    this.toastr.success(message, title, {
      closeButton: isShowCloseButton,
      tapToDismiss: false,
    });
    this.toastr.toastrConfig.preventDuplicates = true;
  }

  /**
   * show error toaster
   * @param title -> title for toaster
   * @param message -> message for toaster
   * @param isShowCloseButton -> close button show/hide default true
   */
  showErrorToaster(title: string, message: string, isShowCloseButton = true) {
    this.toastr.error(message, title, {
      closeButton: isShowCloseButton,
      tapToDismiss: false,
    });
    this.toastr.toastrConfig.preventDuplicates = true;
  }
  /**
   *
   * @param error Error Object
   */
  showErrorMessage(error: any) {
    if (error) {
      this.showErrorToaster('Error', error);
    }
  }
}
