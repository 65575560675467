import { HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';

export interface HttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export interface PaginatedResponse {
  data: any;
  message: string;
  status: string;
  totalItems: number;
  totalPages: number;
  currentPage: number;
}

export class PaginatedRequest {
  page: number;
  size: number;
  sort: { property: string; direction: string };
  constructor() {
    this.page = 0;
    this.size = 10;
    this.sort = { property: 'createdDate', direction: 'ASC' };
  }
  toQueryParams(): string {
    const queryParams = [];
    queryParams.push(`page=${encodeURIComponent(this.page.toString())}`);
    queryParams.push(`size=${encodeURIComponent(this.size.toString())}`);
    queryParams.push(`sort.property=${encodeURIComponent(this.sort.property)}`);
    queryParams.push(
      `sort.direction=${encodeURIComponent(this.sort.direction)}`
    );
    return '?' + queryParams.join('&');
  }
}
export class CustomPagination {
  page: number;
  size: number;
  sort: { property: string; direction: string };
  customFields: Record<string, string>; // New field for custom fields

  constructor() {
    this.page = 0;
    this.size = 10;
    this.sort = { property: 'createdDate', direction: 'DESC' };
    this.customFields = {}; // Initialize custom fields as an empty object
  }

  toQueryParams(): string {
    const queryParams = [];
    queryParams.push(`page=${encodeURIComponent(this.page.toString())}`);
    queryParams.push(`size=${encodeURIComponent(this.size.toString())}`);
    queryParams.push(`sort.property=${encodeURIComponent(this.sort.property)}`);
    queryParams.push(
      `sort.direction=${encodeURIComponent(this.sort.direction)}`
    );

    // Add custom fields to queryParams
    for (const key in this.customFields) {
      queryParams.push(`${key}=${encodeURIComponent(this.customFields[key])}`);
    }

    return '?' + queryParams.join('&');
  }
}

export class Pagination {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  pageSizeOptionArray: number[];

  constructor(currentPage = 0, totalItems = 0, totalPages = 0) {
    this.currentPage = currentPage;
    this.totalItems = totalItems;
    this.totalPages = totalPages;
    this.pageSizeOptionArray = [10, 20, 50, 100];
  }
}
export class AlertDialogData {
  modalTitle?: string;
  headingMsg?: string;
  buttons?: Array<any>;
}
export interface CommonResponse {
  status: string;
  message: string;
  data: any;
}
export interface Address {
  addressLine: string;
  country: string;
  state: string;
  city: string;
  postalCode: number;
}

// modal for common parameters
export interface Param {
  id: string;
  labId: string;
}
