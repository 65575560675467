<div class="modal-outer generic-creation-modal">
  <div mat-dialog-title class="modal-title">
      <p class="heading-title">{{data.modalTitle}}</p>
      <label mat-dialog-close class="close">
          <app-icons name="modal-close" class="svg-icon"> </app-icons>
      </label>
  </div>
  <div mat-dialog-content class="modal-content">
      <div class="details">
          <span>{{ data.headingMsg }}</span>
      </div>
      <div class="button-wrapper">
          <div
        mat-dialog-actions
        *ngFor="let button of data.buttons; let i = index"
      >
        <app-btn text=" {{ button.name }}" color="{{button.color}}"
          (click)="close(button.value)"
        >
          {{ button.name }}
        </app-btn>
      </div>
      </div>
    </div>
    </div>

      